import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context

const defaultContext = {
  activeSlide: 0,
  updateActiveSlider: () => {},
}


const SliderContext = createContext(defaultContext);

// Create a custom hook to use the context
export const useSliderContext = () => {
  return useContext(SliderContext);
};

// Create the context provider component
export const SliderProvider = ({ children }) => {
  const [activeSlide, setActiveSlide] = useState(0);


  const updateActiveSlider = (int) => {
    setActiveSlide(int);
  };

  const value = {
    activeSlide,
    updateActiveSlider,
  };

  return (
    <SliderContext.Provider value={value}>
      {children}
    </SliderContext.Provider>
  );
};
