import React, { createContext, useContext, useState } from 'react';

// Create the context
const MenuContext = createContext();

// Create a custom hook to use the context
export const useMenu = () => {
  return useContext(MenuContext);
};

// Create the context provider component
export const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const value = {
    isMenuOpen,
    toggleMenu,
  };

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};